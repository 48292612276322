.jlt-docs-content {
    margin-top: 24px;
    .jt-container {
        width: 100%;
        padding: 0;
    }
    h1 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.25rem;
    }
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 0.875rem;
    }
    h5 {
        font-size: 0.75rem;
    }
    h5 {
        font-size: 0.625rem;
    }
    > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 8px;
    }
    img {
        box-shadow: 1px 10px 22px -4px rgba(0, 0, 0, 0.2);
        border-radius: 7px;
        margin: 30px auto;
    }
    ul,
    ol {
        // list-style-type: auto;
        margin-left: 20px;
        margin-bottom: 32px;
    }
    p {
        margin-bottom: 32px;
        strong {
            font-weight: 700;
        }
    }
    a {
        color: #42b983;
        font-weight: 600;
        text-decoration: underline;
        strong {
            font-weight: 700;
        }
    }
    code {
        border-radius: 2px;
        color: #e96900;
        margin: 0 2px;
        padding: 3px 5px;
        white-space: pre-wrap;
    }
    pre {
        margin-bottom: 32px;
    }
    pre,
    code,
    p.tip {
        background-color: #f8f8f8;
    }
    .lang-bash {
        color: #525252;
        font-size: 0.8rem;
        padding: 2.2em 24px;
        line-height: inherit;
        margin: 0 2px;
        max-width: inherit;
        overflow: inherit;
        white-space: inherit;
        display: block;
        margin-bottom: 32px;
    }
    .token {
        color: #e96900;
    }
    .token.placeholder,
    .token.variable {
        color: #3d8fd1;
    }
    .warn {
        background: rgba(66, 185, 131, 0.1);
        border-radius: 2px;
        padding: 1rem;
    }
    .tip {
        border-bottom-right-radius: 2px;
        border-left: 4px solid #f66;
        border-top-right-radius: 2px;
        margin: 2em 0;
        padding: 12px 24px 12px 30px;
        position: relative;
        &::before {
            background-color: #f66;
            border-radius: 100%;
            color: #fff;
            content: "!";
            font-family: Dosis, Source Sans Pro, Helvetica Neue, Arial, sans-serif;
            font-size: 14px;
            font-weight: 700;
            left: -12px;
            line-height: 20px;
            position: absolute;
            height: 20px;
            width: 20px;
            text-align: center;
            top: 14px;
        }
    }
}
