.jlt-blog-content,
.jlt-product-desc {
    margin-top: 48px;
    h1 {
        font-size: 3rem !important;
    }
    h2 {
        font-size: 2.25rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.875rem;
    }

    > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 24px 0;
        /* font-size: 24px; */
        color: #1d2939;
        /* line-height: 32px; */
        font-weight: 700;
    }
    ul,
    ol {
        margin-left: 28px !important;
        list-style-type: revert;
        margin-left: 20px;
        margin-bottom: 32px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #475467;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    > p {
        margin: 32px 0;
        color: #475467;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    img {
        margin: 40px 0;
    }
    code {
        border-radius: 2px;
        color: #e96900;
        margin: 0 2px;
        padding: 3px 5px;
        white-space: pre-wrap;
    }
    pre {
        margin-bottom: 32px;
    }
    pre,
    code,
    p.tip {
        background-color: #f8f8f8;
    }
    .lang-bash {
        color: #525252;
        font-size: 0.8rem;
        padding: 2.2em 24px;
        line-height: inherit;
        margin: 0 2px;
        max-width: inherit;
        overflow: inherit;
        white-space: inherit;
        display: block;
        margin-bottom: 32px;
    }
    .token {
        color: #e96900;
    }
    .token.placeholder,
    .token.variable {
        color: #3d8fd1;
    }
    .warn {
        background: rgba(66, 185, 131, 0.1);
        border-radius: 2px;
        padding: 1rem;
    }
    .tip {
        border-bottom-right-radius: 2px;
        border-left: 4px solid #f66;
        border-top-right-radius: 2px;
        margin: 2em 0;
        padding: 12px 24px 12px 30px;
        position: relative;
        &::before {
            background-color: #f66;
            border-radius: 100%;
            color: #fff;
            content: "!";
            font-family: Dosis, Source Sans Pro, Helvetica Neue, Arial, sans-serif;
            font-size: 14px;
            font-weight: 700;
            left: -12px;
            line-height: 20px;
            position: absolute;
            height: 20px;
            width: 20px;
            text-align: center;
            top: 14px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 2.25rem !important;
        }
        h2 {
            font-size: 1.75rem;
        }
        h3 {
            font-size: 1.25rem;
        }
        h4 {
            font-size: 1.25rem;
        }
        h5 {
            font-size: 0.75rem;
        }
        h6 {
            font-size: 0.5rem;
        }
    }
}
